<template>
	<v-sheet class="inventory" style="height: calc(100vh - 88px)">
		<v-row class="d-flex justify-space-between">
			<v-col md="12" class="d-flex justify-space-between">
				<template v-for="(row, index) in listingStatus">
					<v-col
						v-if="
							row.value == 'all' ||
							row.value == 'draft' ||
							row.value == 'approved' ||
							row.value == 'sent_for_approval' ||
							row.value == 'rejected'
						"
						:key="index"
					>
						<StatusCard
							:type="row.type"
							@changeStatus="changeStatus"
							:value="row.value"
							icon="mdi-account-group-outline"
							:heading="row.text"
							:statusCount="row.status_count"
							:statusColor="row.color"
							:textColor="row.textcolor"
						></StatusCard>
					</v-col>
				</template>
			</v-col>
		</v-row>
		<v-row>
			<v-col md="4" class="py-0 my-auto">
				<v-select
					v-if="false"
					v-model="status"
					class="listing-select"
					hide-details
					:disabled="pageLoading"
					:items="listingStatus"
					label="Status"
					solo
					v-on:change="filterRows"
				>
					<template v-slot:item="{ item }">
						<v-list-item-content>
							<v-list-item-title v-text="item.text"></v-list-item-title>
							<v-list-item-subtitle v-text="item.description"></v-list-item-subtitle>
						</v-list-item-content>
						<v-list-item-action>
							<v-chip :color="item.color" text-color="white">
								{{ item.status_count }}
							</v-chip>
						</v-list-item-action>
					</template>
				</v-select>
			</v-col>
			<v-col md="8" class="my-auto text-right listing-right">
				<template v-if="selected.length > 0">
					<v-menu
						v-model="statusOne"
						bottom
						left
						origin="center center"
						transition="slide-y-transition"
						rounded="0"
						offset-y
						max-height="400px"
						:close-on-content-click="false"
						content-class="white-background"
					>
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								depressed
								color="blue darken-4"
								class="text-white"
								tile
								:disabled="pageLoading"
								v-bind="attrs"
								v-on="on"
							>
								<v-icon left>mdi-chevron-down</v-icon>
								Bulk Action
							</v-btn>
						</template>
						<v-list class="py-0" v-if="statusOne">
							<template v-for="(item, i) in listingBulkAction">
								<v-list-item link :key="i" v-on:click="doAction(item.value)">
									<v-list-item-title
										><v-icon v-if="item.icon" left :color="item.color">{{ item.icon }}</v-icon
										>{{ item.title }}</v-list-item-title
									>
								</v-list-item>
								<v-divider v-if="listingBulkAction[i + 1]" :key="`d` + i" class="m-0 p-0"></v-divider>
							</template>
						</v-list>
					</v-menu>
					<v-btn
						depressed
						:disabled="pageLoading"
						tile
						v-on:click="clearSelection"
						color="red lighten-1"
						class="text-white"
					>
						<v-icon left> mdi-close </v-icon>
						Clear Selections
					</v-btn>
				</template>
				<template v-else>
					<v-btn
						v-if="getPermission('adjustment::create')"
						depressed
						:disabled="pageLoading"
						tile
						color="blue darken-4"
						class="text-white"
						:to="{ name: 'adjustment-create' }"
					>
						<v-icon left> mdi-plus </v-icon>
						Create
					</v-btn>
					<v-btn
						v-if="false"
						depressed
						:disabled="pageLoading"
						color="blue darken-4"
						class="text-white"
						tile
					>
						<v-icon>mdi-cog</v-icon>
					</v-btn>
					<v-btn
						v-if="false"
						depressed
						:disabled="pageLoading"
						color="blue darken-4"
						class="text-white"
						tile
						v-on:click="advanceSearch = true"
					>
						<v-icon>mdi-filter</v-icon>
					</v-btn>
					<v-menu
						v-if="false"
						bottom
						left
						origin="center center"
						transition="slide-y-transition"
						rounded="0"
						offset-y
						max-height="400px"
						:close-on-content-click="false"
						content-class="white-background"
					>
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								depressed
								color="blue darken-4"
								class="text-white"
								tile
								:disabled="pageLoading"
								v-bind="attrs"
								v-on="on"
							>
								<v-icon>mdi-menu</v-icon>
							</v-btn>
						</template>
						<v-list class="py-0">
							<template v-for="(item, i) in listingAction">
								<v-list-item link :key="`action-event-${i}`" v-on:click="doAction(item.value)">
									<v-list-item-title
										><v-icon v-if="item.icon" left :color="item.color">{{ item.icon }}</v-icon
										>{{ item.title }}</v-list-item-title
									>
								</v-list-item>
								<v-divider v-if="listingAction[i + 1]" :key="`d` + i" class="m-0 p-0"></v-divider>
							</template>
						</v-list>
					</v-menu>
					<v-menu
						bottom
						left
						origin="center center"
						transition="slide-y-transition"
						rounded="0"
						offset-y
						max-height="400px"
						:close-on-content-click="false"
						content-class="white-background"
					>
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								depressed
								color="blue darken-4"
								class="text-white"
								tile
								:disabled="pageLoading"
								v-bind="attrs"
								v-on="on"
							>
								<v-icon>mdi-table-edit</v-icon>
							</v-btn>
						</template>
						<Draggable
							tag="ul"
							v-model="draggableThead"
							class="draggable-group"
							handle=".draggable-drag-icon"
							v-on:change="updateTable('inventory')"
						>
							<template v-for="cols in draggableThead">
								<li
									class="draggable-group-item"
									style="min-width: 300px !important"
									:key="cols.key"
									v-if="!cols.checkbox"
								>
									<v-checkbox
										dense
										v-model="draggableTheadShow"
										v-bind:value="cols.key"
										:label="cols.name"
										:disabled="cols.fixed || pageLoading"
										color="blue"
										hide-details
										class="mt-0 mb-0"
										v-on:change="updateTableVisiblity('inventory')"
									></v-checkbox>
									<v-icon
										v-if="!pageLoading && !cols.fixed"
										class="draggable-action draggable-drag-icon"
										right
										color="blue"
										>mdi-drag</v-icon
									>
									<v-icon v-else right class="draggable-action" color="grey">mdi-drag</v-icon>
								</li>
							</template>
						</Draggable>
					</v-menu>
					<v-btn
						v-if="false"
						:disabled="pageLoading"
						color="blue darken-4"
						class="text-white"
						v-on:click="exportDialogNew = true"
						tile
						depressed
					>
						<v-icon dark left>mdi-database-export</v-icon> Export
					</v-btn>
				</template>
			</v-col>
		</v-row>
		<SearchCriteria
			v-if="searchEnabled()"
			:search-fields="listingFilter"
			search-string="Adjustment #, Supplier"
			v-on:advance-search="advanceSearch = true"
			v-on:close-search="resetSearch"
		></SearchCriteria>
		<Table
			:page-loading="pageLoading"
			type="adjustment"
			delete-endpoint="adjustment/"
			detail-route="adjustment-detail"
			update-route="adjustment-update"
			v-on:reload:content="filterRows"
			delete-note="All transactions of this adjustment will also be deleted."
		></Table>
		<ExportDialogNew
			endpoint="export-purchase-order"
			title="Export Purchase Order"
			:cols="draggableThead"
			:export-dialog.sync="exportDialogNew"
			v-on:close="exportDialogNew = false"
		></ExportDialogNew>
		<ExportDialogNew
			endpoint="export-purchase-order"
			title="Export Purchase Order"
			:cols="draggableThead"
			:export-dialog.sync="exportDialogNew"
			v-on:close="exportDialogNew = false"
		></ExportDialogNew>
		<ImportDialog
			endpoint="purchase-order/import?type=inventory"
			title="Purchase Order"
			:import-dialog.sync="importDialog"
			v-on:close="importDialog = false"
		></ImportDialog>

		<FilterDialog
			title="Purchase Order"
			:filter-dialog.sync="advanceSearch"
			:btx-filter.sync="listingFilter"
			:btx-query.sync="btxQuery"
			v-on:do-search="doAdvanceSearch"
			v-on:close="advanceSearch = false"
		>
		</FilterDialog>
	</v-sheet>
</template>
<script>
import ListingMixin from "@/core/mixins/listing.mixin";
import StatusCard from "@/view/components/statusCard.vue";
export default {
	name: "Adjustment-listing",
	title: "Listing Adjustment",
	mixins: [ListingMixin],
	components: {
		StatusCard,
		// DatePicker,
	},
	methods: {
		changeStatus(value) {
			this.status = value;
			this.filterRows();
		},
	},
	data() {
		return {
			pageTitle: "Adjustment",
			listingCols: [
				{
					label: "Date Range",
					text: "Date",
					model: "start_date",
				},
				{
					label: "Date Range",
					text: "Date",
					model: "end_date",
				},
				{
					label: "Supplier",
					text: "Supplier",
					model: "supplier",
				},
			],
			filterPurchaseOrderDialog: false,
			pageBreadcrumbs: [{ text: "Adjustment", disabled: true }],
			endpoint: "adjustment",
			defaultFilter: {},
			filter: {
				start_date: null,
				end_date: null,
				purchase_order: null,
			},
		};
	},
};
</script>
